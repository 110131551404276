<template>
  <section class="services">
    <h2 class="shadowed-text" v-html="$t('services')"></h2>
    <div class="services-container">
      <!-- Service cards will be rendered here -->
      <ServiceCard
        v-for="(service, index) in services"
        :key="index"
        :service="service"
        :handleTouchStart="handleTouchStart"
        :handleTouchEnd="handleTouchEnd"
        :activeTouches="activeTouches"
      />
    </div>
  </section>
</template>

<script>
import ServiceCard from './ServiceCard.vue';

export default {
  name: 'ServicesComponent',
  components: {
    ServiceCard,
  },
  props: {
    services: Array,
    handleTouchStart: Function,
    handleTouchEnd: Function,
    activeTouches: Object,
  },
};
</script>

<style scoped>
.services {
  text-align: center;
  padding: 50px 0; 
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 20px; 
  flex-wrap: wrap;
}
.shadowed-text {
  text-shadow: 2px 2px 2px #cccccc;
}
</style>