<template>
  <div
    class="service-card"
    @touchstart="handleTouchStart($event, service.key)"
    @touchend="handleTouchEnd($event, service.key)"
    :class="{ 'active-touch': activeTouches[service.key] }"
  >
    <div class="service-icon">{{ service.icon }}</div>
    <h3 v-html="$t(service.title)"></h3>
    <p v-html="$t(service.details)"></p>
  </div>
</template>

<script>
export default {
  name: 'ServiceCard',
  props: {
    service: {
      type: Object,
      required: true, // makes this prop mandatory
      default: () => ({ key: '', icon: '', title: '', details: '' }), // defines default object structure
    },
    handleTouchStart: {
      type: Function,
      default: () => {}, // default to noop function
    },
    handleTouchEnd: {
      type: Function,
      default: () => {}, // default to noop function
    },
    activeTouches: {
      type: Object,
      default: () => ({}), // default empty object
    },
  },
};
</script>
  
<style scoped>
.service-card {
  background-color: white; 
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: 300px; 
  padding: 20px;
  transform-style: preserve-3d; 
}

.service-card:hover, .service-card.active-touch {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.05);
}

.service-icon {
  font-size: 2.5rem; 
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; 
  display: inline-block; 
}

.service-card:hover .service-icon, .service-card.active-touch .service-icon {
  transform: scale(1.5); 
  opacity: 1;
  z-index: 1; 
}

.service-card h3 {
  margin-bottom: 10px;
  color: #333; 
}

.service-card p {
  color: #666; 
  line-height: 1.5;
}
</style>