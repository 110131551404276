<template>
    <header>
      <div class="logo-container">
        <a href="https://www.instagram.com/higgssgps/" target="_blank" rel="noopener noreferrer">
          <img :src="logo" alt="HomeDestiny Logo" class="logo" />
        </a>
      </div>
      <div class="social-icons">
        <a href="https://www.instagram.com/higgssgps/" target="_blank" rel="noopener noreferrer">
          <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
        </a>
        <button @click="switchLanguage" class="language-btn">
          <img :src="currentLocale === 'en' ? flagPT : flagGB" alt="flag" class="flag-icon" />
        </button>
      </div>
    </header>
</template>
  
<script>
import { store } from '@/store';
import logo from '@/assets/logoHiggs.png'; 
import flagPT from '@/assets/pt.png'; 
import flagGB from '@/assets/gb.png'; 

export default {
  name: 'HeaderComponent',
  data() {
    return {
      logo, 
      flagPT, 
      flagGB, 
      currentLocale: store.currentLocale, // use the store's current locale
    };
  },
  methods: {
    switchLanguage() {
      // Toggle the locale between 'en' and 'pt'
      store.currentLocale = store.currentLocale === 'en' ? 'pt' : 'en';
      this.currentLocale = store.currentLocale; // update the local data property
      this.$i18n.locale = store.currentLocale; // update the locale in the i18n plugin
      console.log('Language switched to:', store.currentLocale);
    },
  },
  watch: {
    // Watcher to handle changes made directly to the store's currentLocale from outside this component
    'store.currentLocale': function (newLocale) {
      this.currentLocale = newLocale;
      this.$i18n.locale = newLocale;
    },
  },
  created() {
    // Sync the component's locale with the store when the component is created
    this.currentLocale = store.currentLocale;
    this.$i18n.locale = store.currentLocale;
  },
};
</script>
  
<style scoped>
    header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(52, 73, 94, 0.8);
        color: white;
        padding: 20px 0;
    }
    
    .logo-container, .social-icons {
        display: flex;
        align-items: center;
    }
    
    .logo {
        width: 100px;
        height: auto;
    }
    
    .social-icons {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    a {
        color: #E1306C; /* Instagram color */
        margin: 10px;
        transition: color 0.3s;
    }

    a:hover {
        color: #BC2A8D; /* Darkened Instagram color */
    }
    .flag-icon {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
    
    .language-btn {
        background: none;
        border: none;
        font-size: 1.2em;
        cursor: pointer;
    }
</style>
  