<template>
  <div id="app">
    <HeaderComponent
      :key="componentKey" 
      :currentLocale="currentLocale" 
      @localeChanged="handleLocaleChange"
    />

    <main>
      <AboutComponent />
      <ServicesComponent   
        :services="services"
        :handleTouchStart="handleTouchStart"
        :handleTouchEnd="handleTouchEnd"
        :activeTouches="activeTouches"
      />
      <RoomsComponent />
      <ContactForm />
      <FooterComponent />
      <PrivacyBanner /> 
    </main>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import AboutComponent from './components/AboutComponent.vue';
import ServicesComponent from './components/ServicesComponent.vue';
import RoomsComponent from './components/RoomsComponent.vue';
import ContactForm from './components/ContactForm.vue';
import FooterComponent from './components/FooterComponent.vue';
import PrivacyBanner from './components/PrivacyBanner.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    AboutComponent,
    ServicesComponent,
    RoomsComponent,
    ContactForm,
    FooterComponent,
    PrivacyBanner
  },
  //DEBUG flag change language
  // mounted() {
  //   console.log('Mounted i18n locale:', this.$i18n.locale); // Accessing i18n locale reactively within a component
  // },
  data() {
    return {
      currentLocale: 'en', // default locale
      services: [
        { key: 'service1', icon: '🏗️', title: 'scaffolding', details: 'scaffoldingDetails' },
        { key: 'service2', icon: '🏢', title: 'facades', details: 'facadesDetails' },
        { key: 'service3', icon: '🛏️', title: 'property', details: 'propertyDetails' },
        { key: 'service4', icon: '💡', title: 'ceilings', details: 'ceilingsDetails' },
      ],
    };
  },
  provide() {
    return {
      currentLocale: this.currentLocale,
    };
  },
  methods: {
    handleLocaleChange(newLocale) {
      //DEBUG
      // console.log('Caught locale change event. New Locale:', newLocale);
      // console.log('Old Locale:', this.currentLocale);
      this.currentLocale = newLocale;
      this.$i18n.locale = newLocale; // change the locale of vue-i18n
      // console.log('Updated Locale:', this.$i18n.locale); 
    },
  }
};
</script>

<style>
body, p, h1, h2, h3, h4, h5, h6, textarea, button, label, .service-icon {
  cursor: default; 
}

/* Change the color of selected text and the background color of the selection */
::selection {
  background: transparent; /* Use your background color */
  color: inherit; /* Use your text color */
}

.contact form input, .contact form textarea {
  cursor: text; 
  user-select: text;
}

.contact form input::selection, .contact form textarea::selection {
  background: blue; 
  color: white;
}
</style>