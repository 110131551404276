<template>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
            &copy; 2020 HiGGS|SGPS LDA.
        </p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: rgba(52, 73, 94, 0.8); 
    color: white;
    padding: 20px 0;
    text-align: center;
  }
  </style>
  