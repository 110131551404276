<template>
  <div v-if="!hasAgreed" class="privacy-banner">
    <p v-html="$t('privacy')"></p> <!-- Using v-html to render the message as HTML -->
    <button @click="agreeToPrivacy">{{ $t('understand') }}</button> <!-- Text for the button -->
  </div>
</template>

<script>
export default {
  name: 'PrivacyBanner',
  data() {
    return {
      hasAgreed: false,
    };
  },
  created() {
    this.hasAgreed = !!localStorage.getItem('privacy-agreed');
  },
  methods: {
    agreeToPrivacy() {
      this.hasAgreed = true;
      localStorage.setItem('privacy-agreed', 'true'); // save their choice
    }
  }
};
</script>

<style>
.privacy-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 1000; /* TODO make sure it doesn't get hidden behind other elements */
}

.privacy-banner p {
  margin: 0;
  padding: 0;
  line-height: 1.5; /* adjust as necessary for readability */
}

.privacy-banner button {
  margin-left: 15px;
  background-color: white;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
