import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createI18n } from 'vue-i18n' // import i18n for english/portuguese
import './css/GlobalStyles.css' 

const messages = {
    en: {
        aboutUs: 'Turning the unknown into home, with {0}, {1}, and {2} at every step.',
        comfort: 'Comfort',
        transparency: 'Transparency',
        innovation: 'Innovation',
        services: 'Services',
        scaffolding: 'Scaffolding',
        scaffoldingDetails: 'Professional Rental and Assembly.',
        facades: 'Facades &#124; Roofs',
        facadesDetails: 'Expert Remodeling and Painting.',
        property: 'Property Leasing &#124; Management',
        propertyDetails: 'Comprehensive Services.',
        ceilings: 'Interior Ceilings',
        ceilingsDetails: 'Innovative and Creative Solutions',
        rooms: 'Rooms',
        name: "Name:",
        phoneNumber: "Phone Number:",
        message: "Message:",
        privacy: "We use essential cookies to ensure site functionality and improve your experience. We also collect information you submit through the contact form. By continuing to use this site, you agree to our Privacy Policy.",
        understand: "I Understand",
    },
    pt: {
        aboutUs: 'Transformando o desconhecido em lar, com {0}, {1} e {2} a cada passo.',
        comfort: 'Conforto',
        transparency: 'Transparência',
        innovation: 'Inovação',
        services: 'Serviços',
        scaffolding: 'Andaimes',
        scaffoldingDetails: 'Arrendamento Profissional e Montagem',
        facades: 'Fachadas &#124; Coberturas',
        facadesDetails: 'Remodelação e Pintura Especializada.',
        property: 'Arrendamento &#124; Gestão de Imóveis',
        propertyDetails: 'Serviços Completos.',
        ceilings: 'Tectos Interiores',
        ceilingsDetails: 'Soluções Inovadoras e Criativas',
        rooms: 'Quartos',
        name: "Nome:",
        phoneNumber: "Número de Telefone:",
        message: "Mensagem:",
        privacy: "Usamos cookies essenciais para garantir a funcionalidade do site e melhorar sua experiência. Também coletamos informações que você envia através do formulário de contato. Ao continuar a usar este site, você concorda com nossa Política de Privacidade.",
        understand: "Eu compreendo"
    }
  }
  
  const i18n = createI18n({
    locale: store.currentLocale,
    fallbackLocale: 'pt', // set fallback locale
    messages, // set locale messages
  })

library.add(faInstagram);

const app = createApp(App);
app.config.globalProperties.$appState = store;
app.use(i18n)
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');