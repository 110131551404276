<template>
    <section class="about">
      <!-- <h2>About Us</h2> -->
      <p v-html="translatedAboutUs"></p>
      <!-- <p>Turning the unknown into home, with <b>Comfort</b>, <b>Transparency</b>, and <b>Innovation</b> at every step.</p> -->
    </section>
</template>

<script>
  export default {
    name: 'AboutComponent',
    computed: {
      translatedAboutUs() {
        const comfort = `<b>${this.$t('comfort')}</b>`;
        const transparency = `<b>${this.$t('transparency')}</b>`;
        const innovation = `<b>${this.$t('innovation')}</b>`;
  
        return this.$t('aboutUs', [comfort, transparency, innovation]);
      }
    },
    methods: {
        switchLanguage() {
            const newLocale = this.currentLocale === 'en' ? 'pt' : 'en';
            this.$parent.setLocale(newLocale); // call method from parent
        },
    },
  }
</script>
  
  <style scoped>
  .about {
    max-width: 95%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: auto; 
    margin-right: auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
  }
  </style>
  