<!-- without backend -->
<template>
  <section class="contact">
    <h2>{{ $t('Contact Us') }}</h2>
    <form action="https://formspree.io/f/{your_form_id}" method="POST">
      <!-- Fields for the user's name, email, phone, and message -->
      <div class="input-group">
        <label for="name"><u v-html="$t('name')"></u></label>
        <input type="text" name="name" id="name" v-model="form.name" required>
      </div>

      <div class="input-group">
        <label for="email"><u>{{ $t('Email') }}:</u></label> 
        <input type="email" name="_replyto" id="email" v-model="form.email" required>
      </div>

      <div class="input-group">
        <label for="phone">{{ $t('phoneNumber') }}</label> 
        <input type="tel" name="phone" id="phone" v-model="form.phone" required>
      </div>

      <div class="input-group">
        <label for="message"><u v-html="$t('message')"></u></label> 
        <textarea name="message" id="message" v-model="form.message" required></textarea>
      </div>

      <!-- Formspree specific fields -->
      <input type="hidden" name="_subject" value="New submission from your website!" />
      <input type="text" name="_gotcha" style="display:none" /> <!-- Spam filter field -->
      <button type="submit">{{ $t('Submit') }}</button>
    </form>
  </section>
</template>

<!-- with backend | without backend mailtolink-->
<!-- <template>
    <section class="contact">
      <h2>Contact Us</h2>
      <form @submit.prevent="submitForm">
        <div class="input-group">
          <label for="name"><u v-html="$t('name')"></u></label>
          <input type="text" id="name" v-model="name" required>
        </div>
        <div class="input-group">
          <label for="email"><u>Email:</u></label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="input-group">
          <label for="phone"><p v-html="$t('phoneNumber')"></p></label>
          <input type="tel" id="phone" v-model="phone">
        </div>
        <div class="input-group">
          <label for="message"><u v-html="$t('message')"></u></label>
          <textarea id="message" v-model="message" required></textarea>
        </div>
        <input type="text" name="honeypot" v-model="honeypot" style="display:none" />
        <button type="submit">Submit</button>
      </form>
    </section>
  </template> -->
  
  <script>
  export default {
    name: 'ContactForm',
    data() {
      return {
        form: {
          name: '',
          email: '',
          phone: '',
          message: ''
        }
      }
    },
      methods: {
        submitForm() {
          // Check if honeypot field is filled, which indicates spam
          if (this.honeypot) return;
        }
      }
    }
    //WITHOUT BACKEND EMAIL LOGIN
    // methods: {
    //   submitForm() {
    //   // Check if honeypot field is filled, which indicates spam
    //   if (this.honeypot) return;

    //   // Construct the mailto link
    //   const subject = encodeURIComponent('Contact Form Submission');
    //   const body = encodeURIComponent(
    //     `Name: ${this.name}\nEmail: ${this.email}\nPhone: ${this.phone}\nMessage: ${this.message}`
    //   );
    //   const mailToString = `mailto:higgspt@higgs.pt?subject=${subject}&body=${body}`;

    //   // Open the user's email client
    //   window.location.href = mailToString;
    // },
      //WITH BACKEND
      // async submitForm() {
      //   try {
      //     const response = await fetch('http://localhost:3001/submit-form', {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json'
      //       },
      //       body: JSON.stringify({
      //         name: this.name,
      //         email: this.email,
      //         phone: this.phone,
      //         message: this.message,
      //         honeypot: this.honeypot
      //       })
      //     });
  
      //     const data = await response.json();
      //     if (data.success) {
      //       alert(data.message);
      //     } else {
      //       alert('Error submitting form.');
      //     }
      //   } catch (error) {
      //     console.error('There was an error submitting the form:', error);
      //     alert('There was an error submitting the form.');
      //   }
      // },
  //   }
  // }
  </script>
  
  <style scoped>
.contact {
  text-align: center;
  max-width: 95%;
  margin-left: auto; 
  margin-right: auto;
  margin-bottom: 40px;
  background-color: rgba(255, 255, 255, 0.8); 
  padding: 20px;
  border-radius: 10px;
}
.contact form {
  max-width: 900px;  
  margin: 0 auto; 
}

.input-group input, .input-group textarea {
  width: calc(100% - 20px); /* This makes the input fields take up the full width of the form, considering padding. Adjust if your input has different padding. */
  margin: 10px; /* Adjusts for alignment and spacing */
}

.input-group p {
  margin: 0;
}
.input-group input {
  min-height: 30px;
}
.input-group textarea {
  resize: none;
}
  </style>
  