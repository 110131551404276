<template>
  <section class="rooms">
    <h2 class="shadowed-text" v-html="$t('rooms')"></h2>
    <div class="rooms-container">
      <div
        v-for="(room, index) in rooms"
        :key="room.id"
        class="room-card"
        v-on:touchstart="handleTouchStart($event, 'room' + index)"
        v-on:touchend="handleTouchEnd($event, 'room' + index, room.link)" 
        v-bind:class="{ 'active-touch': activeTouches['room' + index] }"
      >
        <a :href="room.link" target="_blank" rel="noopener noreferrer" class="room-content">
          <div class="room-image">
            <img :src="room.image" :alt="room.description" />
          </div>
          <div class="room-info">
            <p>{{ room.description }}</p>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RoomsComponent',
  data() {
    return {
      rooms: [
          {
            id: 1,
            image: require('@/assets/ad1.jpg'), // change path if necessary
            description: 'Arroios | Lisboa',
            link: 'https://www.idealista.pt/imovel/29596287/'
          },
          {
            id: 2,
            image: require('@/assets/ad2.jpg'), // change path if necessary
            description: 'Ajuda | Lisboa',
            link: 'https://www.idealista.pt/imovel/29629530/'
          },
          {
            id: 3,
            image: require('@/assets/ad3.jpg'), // change path if necessary
            description: 'Belém | Lisboa',
            link: 'https://www.idealista.pt/imovel/29629586/'
          },
          {
            id: 4,
            image: require('@/assets/ad4.jpg'), // change path if necessary
            description: 'Ajuda | Lisboa',
            link: 'https://www.idealista.pt/imovel/30542478/'
          },
        ],
        activeTouches: {},
        touchStartTimes: {},
        //... other data properties
    };
  },
  methods: {
      handleTouchStart(event, key) {
        // No need to prevent default here; we're just recording the start time
        this.touchStartTimes[key] = Date.now(); // Record the current time
      },

      handleTouchEnd(event, key, link = null) {
        // Check if the key exists in touchStartTimes
        if (!this.touchStartTimes[key]) {
            console.error(`No touch start time recorded for key: ${key}`);
            return; // Exit the method
        }

        // Determine if it was a short tap
        if (Date.now() - this.touchStartTimes[key] < 500) { // Short tap
            // It's a short tap. If it's a link, we want the default behavior (navigation),
            // so we don't prevent it. The browser will handle the navigation for us.
            if (link) {
            // No need to manually navigate; the browser will do it for us since we're not calling preventDefault
            }
        } else {
            // It was a long press or something else; we prevent the default behavior
            event.preventDefault();
            // Deactivate the effect, if necessary
            if (this.activeTouches[key]) {
            this.activeTouches[key] = false;
            this.activeTouches = {...this.activeTouches}; // Trigger reactivity in Vue 3
            }
        }

        // Clean up the recorded start time
        delete this.touchStartTimes[key];
    },
  }
}
</script>

<style scoped>
.rooms {
  text-align: center;
  padding: 50px 0; 
}

.rooms-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
  justify-content: center; 
}

.room-card {
  width: 300px; 
  border: solid 1px transparent; /* Invisible border to keep structure */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures the image and card content follow the border radius */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transform to transitions */
  margin-left: 15px; /* Adjust based on the desired gap between cards */
  margin-right: 15px; /* Adjust based on the desired gap between cards */
  background: transparent; /* Ensure no background color is interfering */
}

.room-card:hover,
.active-touch { /* added active-touch class for touch devices */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.05);
}

.room-content {
  display: block;
  color: inherit; 
  text-decoration: none; 
  position: relative; /* Establish a stacking context */
  z-index: 2; /* Higher than the image */

  /* Remove outline */
  outline: none;
  /* Remove tap highlight color for mobile browsers */
  -webkit-tap-highlight-color: transparent; /* iOS and old Android */
  -webkit-touch-callout: none; /* iOS specific */
}

.room-image img {
  display: block;
  width: 100%; /* Full width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Cover the entire area */
  transition: transform 0.3s ease; /* Smooth transition */
  margin: 0; /* Remove margins */
  border-radius: 10px; /* Rounded corners for the image as well */
}

.room-card:hover .room-image img,
.active-touch .room-image img { /* added active-touch class for touch devices */
  transform: scale(1.1); /* Zoom effect on hover */
}

.room-info {
  margin: 0;
  padding: 15px; 
  text-align: center;
  background-color: #fff; /* Set a background color */
  position: relative; /* Keep it above the image */
  z-index: 3; /* Higher z-index so it stays on top of the image */
}

.room-info p {
  margin: 0;
}

.room-card:hover .room-info,
.active-touch .room-info { /* added active-touch class for touch devices */
  background-color: #f8f8f8; /* highlight of ad description */
}

/* Change the color of selected text and the background color of the selection */
::selection {
  background: transparent; /* Use your background color */
  color: inherit; /* Use your text color */
}

.shadowed-text {
  text-shadow: 2px 2px 2px #cccccc;
}
</style>
